@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$green: #9ABA5A;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$dark-green: #66930B;
$black: #010200;
$some-blue: #768CA0;
$white: #FFFFFF;
$gold: #FFC300;
$cream: #EBF3BD;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $dark-green;
$link: $gold;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $cream;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$text: $white;

$footer-background-color: $dark-green;
$box-background-color: $black;
$card-background-color: $dark-green;

section {
  text-align: center; }

h1#homepage-header, h2#homepage-subheader {
  color: $gold; }

p.footer-text {
  color: $dark-green; }

nav#nav {
  background-color: $black;
  width: 100%;
  display: flex;
  justify-content: center; }

ul.nav-list {
  display: flex;
  justify-content: center;
  flex-grow: 1; }

li.nav-item {
  display: flex;
  justify-content: center;
  flex-grow: 1; }

li.nav-item:hover {
  background-color: $dark-green; }

a.nav-link {
  flex-grow: 1; }

p.nav-text {
  color: $gold; }

#dam-header {
  padding-top: 30px; }

#project-left-arrow, #project-right-arrow {
  background-color: transparent;
  color: $dark-green;
  border: transparent;
  font-size: 30px;
  outline: none;
  padding-top: 10px; }

#project-left-arrow:hover, #project-right-arrow:hover {
  border: transparent;
  color: $gold; }

h1.project-header {
  color: $gold;
  padding-top: 20px; }


#aboutme-header {
  color: $gold;
  font-size: 30pt;
  padding-top: 8px; }

.aboutme-info {
  font-size: 16pt;
  color: $black;
  padding-bottom: 5px;
  padding-left: 10px; }

#about-me-text {
  text-align: left;
  width: 63%;
  max-height: 600px;
  overflow-x: auto; }

p.project-info {
  color: $black;
  font-size: 16pt; }

#front-end-info {
  width: 50%;
  float: left; }

div#sfw-card {}

div#sfw-container {
  width: 800px; }

.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative; }


.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%; }

li.list-item {
  text-align: center;
  font-size: 15px; }

p.front-back-text {
  padding-left: 1ch; }

.front-back-headers {
  padding-left: 2ch; }

#sax {
  text-align: center; }

#about-me-text {
  display: flex;
  flex-direction: column; }

#contact-me-card {
  background-color: $gold;
  text-align: center;
  display: flex;
  flex-direction: column; }

.aboutme-content-flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between; }

#profile-pic-contact-info {
  width: 50%; }

#email-icon {
  font-size: 20pt; }

.contact-info {
  color: $black; }

.contact-info a {
  color: $black; }

.contact-info a:hover {
  color: $dark-green; }

#contact-info-header {
  font-weight: bold;
  padding-top: 10px;
  text-align: center; }

#front-back-info {
  display: flex;
  justify-content: space-evenly;
  color: $black; }

li.front-back {
  font-size: 16pt; }

li::marker {
  color: $black;
  content: '-'; }



// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/form.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
@import '../node_modules/bulma/sass/components/menu.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/level.sass';
@import '../node_modules/bulma/sass/layout/footer.sass';
@import '../node_modules/bulma/sass/elements/box.sass';
@import "../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import '../node_modules/bulma/sass/components/card.sass';
